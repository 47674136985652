import { Box, 
    Grid, 
    Paper, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow } from "@mui/material"
import { FunctionComponent } from "react"
import '../TopSongs/TopSongs.scss';

type TopSongsProps = {
    tracksGaniers: any[]; 
    refresh: () => void; 
}

export const TopSongs: FunctionComponent<TopSongsProps> = (props) => { 
  console.log(props.tracksGaniers);
return (
  <div className="top-songs">
     <span className="top-songs-title">This week's biggest gainers</span>
  <div className="table-content">
   <Grid item display="flex" flexDirection="row">
   <TableContainer component={Paper} style={{maxHeight: '400px'}}>
   <Table aria-label="simple table">
           <TableHead>
           <TableRow>
               <TableCell width={250} align="left"> Artist&nbsp;&nbsp;-&nbsp;&nbsp;Track </TableCell>
               <TableCell width={200} align="left">LW</TableCell>
               <TableCell width={200} align="left">TW</TableCell>
               <TableCell width={200} align="left">YTD</TableCell>
               <TableCell width={200} align="left">% Change</TableCell>
           </TableRow>
           </TableHead>
           <TableBody>
            {!!props.tracksGaniers ?
           <>
              {props.tracksGaniers.map((item : any) => (
                <TableRow key={item.Title}
                  >
                    <TableCell width={150} component="th" scope="row">
                    {item.Artist} - {item.Title}
                    </TableCell>
                    <TableCell width={200} align="left">{item['Last Period'].toLocaleString()}</TableCell>
                    <TableCell width={200} align="left">{item['This Period'].toLocaleString()}</TableCell>
                    <TableCell width={200} align="left">{item.YTD.toLocaleString()}</TableCell>
                    <TableCell width={200} align="left">{`${item['% Chg'].toFixed(2).toLocaleString()}%`}</TableCell>

                </TableRow>

            ))} 
            
            </> : <Box padding={3}> 
             
               </Box> }
            </TableBody>
           </Table>
       </TableContainer>
   </Grid>     
   </div> 
   </div>      
)
}