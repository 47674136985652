import { Box, 
         Grid, 
         Paper, 
         Table, 
         TableBody, 
         TableCell, 
         TableContainer, 
         TableHead, 
         TableRow } from "@mui/material"
import { FunctionComponent, useState } from "react"
import '../AllClients/AllClients.scss';
import PhotoHolder from "./Photo/PhotoHolder";

type AllClientsProps = {
    photoName: any;
    clients: any[]; 
    client: any; 
    globalStreams: any; 
    globalAudioStreams: any;
    globalVideoStreams: any; 
    globalTrackDownloads: any; 
    usPhysicalAlbums: any; 
    totalConsumption: any; 
    selectClient: (name: string) => void; 
    refresh: () => void; 
}

export const AllClients: FunctionComponent<AllClientsProps> = (props) => {

    return (
       
        <Grid item display="flex" flexDirection="row">
        <TableContainer component={Paper}>
        <Table 
     aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="right" >This Week</TableCell>
                    <TableCell align="right">Last Week</TableCell>
                    <TableCell align="right">% Change</TableCell>
                    <TableCell align="right">Year To Date</TableCell>
                    <TableCell align="right">Life To Date</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
        {!!props.client && !!props.client.MusicConnectArtistID && props.totalConsumption.tp !== 0 ?
                <>
                                { props.totalConsumption != null ?
                (<TableRow
                                    key="Total Consumption"
                                >
                                    <TableCell component="th" scope="row">
                                        {"Total Consumption"}
                                    </TableCell>
                                    <TableCell align="right">{props.totalConsumption.tp.toLocaleString()}</TableCell>
                                    <TableCell align="right">{props.totalConsumption.lp.toLocaleString()}</TableCell>
                                    <TableCell align="right">{`${parseFloat((((props.totalConsumption.tp - props.totalConsumption.lp) / props.totalConsumption.lp) * 100).toFixed(2)).toLocaleString()}%`}</TableCell>
                                    <TableCell align="right">{props.totalConsumption.ytd.toLocaleString()}</TableCell>
                                    <TableCell align="right">{props.totalConsumption.atd.toLocaleString()}</TableCell>
                </TableRow>) : (     <TableRow 
                                            key="Total Consumption"
                                        >
                                            </TableRow>)
                    }
                { props.globalTrackDownloads != null ?
                (<TableRow
                                    key="Global Track Downloads"
                                >
                                        <TableCell component="th" scope="row">
                                            {"Global Track Downloads"}
                                        </TableCell>
                                        <TableCell align="right">{props.globalTrackDownloads.tp.toLocaleString()}</TableCell>
                                        <TableCell align="right">{props.globalTrackDownloads.lp.toLocaleString()}</TableCell>
                                        <TableCell align="right">{`${parseFloat((((props.globalTrackDownloads.tp - props.globalTrackDownloads.lp) / props.globalTrackDownloads.lp) * 100).toFixed(2)).toLocaleString()}%`}</TableCell>
                                        <TableCell align="right">{props.globalTrackDownloads.ytd.toLocaleString()}</TableCell>
                                        <TableCell align="right">{props.globalTrackDownloads.atd.toLocaleString()}</TableCell>
                </TableRow>) :(
                                            <TableRow 
                                            key="Global Track Downloads"
                                        >
                                       
                                            </TableRow>
                                          )
                                        }
                                          
                { props.globalStreams != null ?
                (<TableRow 
                                        key="Global Total Streaming"
                                    >
                                       
                                        <TableCell component="th" scope="row">
                                            {"Global Total Streaming"}
                                        </TableCell>
                                        <TableCell align="right">{props.globalStreams.tp.toLocaleString()}</TableCell>
                                        <TableCell align="right">{props.globalStreams.lp.toLocaleString()}</TableCell>
                                        <TableCell align="right">{`${parseFloat((((props.globalStreams.tp - props.globalStreams.lp) / props.globalStreams.lp) * 100).toFixed(2)).toLocaleString()}%`}</TableCell>
                                        <TableCell align="right">{props.globalStreams.ytd.toLocaleString()}</TableCell>
                                        <TableCell align="right">{props.globalStreams.atd.toLocaleString()}</TableCell>
                                     
                                        </TableRow>)    :  (
                                            <TableRow 
                                            key="Global Total Streaming"
                                        >
                                        
                                            </TableRow>
                                          )
                                        }
                { props.globalAudioStreams != null ?
                (
                <TableRow
                                        key="Global Audio Streaming"
                                   >
                                        <TableCell component="th" scope="row">
                                            {"Global Audio Streaming"}
                                        </TableCell>
                                        <TableCell align="right">{props.globalAudioStreams.tp.toLocaleString()}</TableCell>
                                        <TableCell align="right">{props.globalAudioStreams.lp.toLocaleString()}</TableCell>
                                        <TableCell align="right">{`${parseFloat((((props.globalAudioStreams.tp - props.globalAudioStreams.lp) / props.globalAudioStreams.lp) * 100).toFixed(2)).toLocaleString()}%`}</TableCell>
                                        <TableCell align="right">{props.globalAudioStreams.ytd.toLocaleString()}</TableCell>
                                        <TableCell align="right">{props.globalAudioStreams.atd.toLocaleString()}</TableCell>
                </TableRow>)    :  (
                                            <TableRow 
                                            key="Global Audio Streaming"
                                        >
                                   
                                            </TableRow>
                                          )
                                        }
                { props.globalVideoStreams != null ?
                (
                <TableRow
                                        key="Global Video Streaming"
                                    >
                                        <TableCell component="th" scope="row">
                                            {"Global Video Streaming"}
                                        </TableCell>
                                        <TableCell align="right">{props.globalVideoStreams.tp.toLocaleString()}</TableCell>
                                        <TableCell align="right">{props.globalVideoStreams.lp.toLocaleString()}</TableCell>
                                        <TableCell align="right">{`${parseFloat((((props.globalVideoStreams.tp - props.globalVideoStreams.lp) / props.globalVideoStreams.lp) * 100).toFixed(2)).toLocaleString()}%`}</TableCell>
                                        <TableCell align="right">{props.globalVideoStreams.ytd.toLocaleString()}</TableCell>
                                        <TableCell align="right">{props.globalVideoStreams.atd.toLocaleString()}</TableCell>
                </TableRow>)    :  (
                                            <TableRow 
                                            key="Global Video Streaming"
                                        >
                                    
                                            </TableRow>
                                          )
                                        }
                { props.usPhysicalAlbums != null ?
                (
                <TableRow
                                        key="U.S. Physical Albums"
                                    >
                                        <TableCell component="th" scope="row">
                                            {"U.S. Physical Albums"}
                                        </TableCell>
                                        <TableCell align="right">{props.usPhysicalAlbums.tp.toLocaleString()}</TableCell>
                                        <TableCell align="right">{props.usPhysicalAlbums.lp.toLocaleString()}</TableCell>
                                        <TableCell align="right">{`${parseFloat((((props.usPhysicalAlbums.tp - props.usPhysicalAlbums.lp) / props.usPhysicalAlbums.lp) * 100).toFixed(2)).toLocaleString()}%`}</TableCell>
                                        <TableCell align="right">{props.usPhysicalAlbums.ytd.toLocaleString()}</TableCell>
                                        <TableCell align="right">{props.usPhysicalAlbums.atd.toLocaleString()}</TableCell>
                </TableRow>)    :  (
                                            <TableRow 
                                            key="U.S. Physical Albums"
                                        >
                                           
                                            </TableRow>
                                          )
                                        }
                                        </>
                : <Box padding={3}> 
                    {"Select a Client" }
                    </Box> }
                 </TableBody>
                </Table>
            </TableContainer>
        </Grid>          
    )
}