import { FunctionComponent, 
    useCallback, 
    useContext, 
    useEffect, 
    useState } from "react";
import ClientSelector from "./ClientSelector";
import { ClientContext } from "../../contexts/ClientContext";
import axios from "../__shared__/axios"; 
import * as consumptionIds from "../__shared__/luminateIds/consumptionIds.json";
import { isReadable } from "stream";
import { isTypeReferenceNode } from "typescript";
import { AppContext } from "../../contexts/AppContext";
import { CalculatorContext } from "../../contexts/CalculatorContext";

type ContainerProps = {
weekId: string; 
}

export const Container: FunctionComponent<ContainerProps> = (props) => { 

    const { section, setSection } = useContext(AppContext);
    const { client, setClient } = useContext(ClientContext);
    const { isRefreshed, setIsRefreshed } = useContext(ClientContext);
    const { isAlbumOrTrack, setAlbumOrTrack } = useContext(ClientContext);
    const { clientItem, setClientItem } = useContext(ClientContext);
    const { clientDiscography, setClientDiscography } = useContext(ClientContext);
    const { clientOptions, setClientOptions } =  useContext(ClientContext);
    
    const { selectedPlatforms, setSelectedPlatforms } = useContext(CalculatorContext);
    const { selectedAudioVideo, setSelectedAudioVideo } = useContext(CalculatorContext);
    const { streamingInputs, setStreamingInputs } = useContext(CalculatorContext);
    const { calculatedValues, setCalculatedValues } = useContext(CalculatorContext);
    const { streamingNumber, setStreamingNumber } = useContext(CalculatorContext);

    const [_clientData, _setClientData] = useState<any>(null); 

    
const handleSelectClient = (clientName: any) => { 
    if (!!clientName) { 
        var client = clientOptions.find(_=> _.Artist === clientName)
        setClient(client);
        localStorage.setItem('lastSelectedClient', client.Artist);
    }
    else {
        setClient(null);      
        localStorage.removeItem('lastSelectedClient');
    }
    setClientItem("");
    setAlbumOrTrack('');
    setClientDiscography({});
}

const handleSelectItem = (item: any) => { 
    setClientItem(item);
    if(!item){
        setAlbumOrTrack('');
    }
}

const handleRefresh = () => {
   setClient(client);
   setIsRefreshed(true);
}

const handleClear = () => {
  setSelectedPlatforms([]);
  setSelectedAudioVideo([]);
  setStreamingInputs([]);
  setCalculatedValues([]);
  setStreamingNumber({});
}

useEffect(() => { 
    const fetchData = async () => {
        const res = await axios.get(`/artists`)
        if (res instanceof Error) 
            throw (res); 
        
        if (res["status"] === 200 && res["data"] !== null) { 
            if (res["data"]["error"])
                 throw (res["data"]["error"]); 

            const sortedArtists = [...res["data"].artists].sort((a, b) => {
              if (a.Artist > b.Artist) return 1;
              if (a.Artist < b.Artist) return -1;
              return 0; // names are equal
          });

            setClientOptions(sortedArtists);
            // setClientOptions(([...res["data"].artists]));       
        }
      };
  
      fetchData(); 
    }, []);

useEffect(() => {
    const lastSelectedClient = localStorage.getItem('lastSelectedClient');
    if (lastSelectedClient) {
        var client = clientOptions.find((c: any) => c.Artist === lastSelectedClient);
        setClient(client);
    }
}, []);

useEffect(() => {
    const fetchDiscography = async () => {
        if (!!client && !!client.MusicConnectArtistID) {
          try {
            const songsResponse = await axios.post('/artist/top-songs', {
                artistId: client.MusicConnectArtistID,
                artist: client.Artist

              });
              const albumsResponse = await axios.post('/artist/top-albums', {
                artistId: client.MusicConnectArtistID,
                artist: client.Artist
              });

         
            const songs = songsResponse.data;
            const albums = albumsResponse.data;
            
            let items = [];
            let discography = {};
            if (songs.length > 0) {
              items.push({ name:'', id:'1', type: 'header', label: 'Songs' });
              songs.forEach((item: any) => {
                items.push({ type: 'Track', name: item.name, id: item.id });
              });
            }
  
            if (albums.length > 0) {
              items.push({name:'', id:'2', type: 'header', label: 'Albums' });
              albums.forEach((item: any) => {
                items.push({ type: 'Album', name: item.name, id: item.id });
              });
            }
            
            items.map((item) => { 
                const key = `${item.name}_${item.id}`;
                discography = {...discography, [key]: {type: item.type, id: item.id} }
            }); 

            setClientDiscography(discography);
          } catch (error) {
            console.error('Error fetching discography:', error);
          }
        }
      };
  
      fetchDiscography();
}, [client])

useEffect(() => {
    if (!!clientDiscography && !!clientItem) { 
        const type = clientDiscography[clientItem]["type"];
        setAlbumOrTrack(type);
    }
}, [clientItem]);

return (
   <ClientSelector
        section={section}
        client={client}
        clients={clientOptions}
        discography={clientDiscography}
        item={clientItem}
        selectClient={handleSelectClient}
        selectDiscography={handleSelectItem}
        refresh={handleRefresh}
        clear={handleClear}

        {...props} /> 
)}

export default Container; 