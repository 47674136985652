import { Box, 
         Button, 
         FormControl, 
         Grid, 
         MenuItem, 
         Paper, 
         Select, 
         Table, 
         TableBody, 
         TableCell, 
         TableContainer, 
         TableHead, 
         TableRow } from "@mui/material"
import RefreshIcon from "@mui/icons-material/Refresh";
import { FunctionComponent } from "react"
import '../Consumption/Consumption.scss';
import PhotoHolder from "../AllClients/Photo/PhotoHolder";




type ConsumptionProps = { 
    client: string; 
    clients: any[];
    discography: any; 
    data: any;
    item: any; 
    albumOrTrack:  string ;   
    refresh: () => void; 
}



export const Consumption: FunctionComponent<ConsumptionProps> = (props) => { 

    const albumData = () => { 

        let consumption = {tp: 0, lp: 0, ytd: 0, atd: 0}; 
        let streaming = {tp: 0, lp: 0, ytd: 0, atd: 0}; 
        let physicalAlbums = {tp: 0, lp: 0, ytd: 0, atd: 0}; 

        console.log("here ", props.data[props.item]["Total Consumption"]); 
        consumption["tp"] = props.data[props.item]["Total Consumption"]["tp"]; 
        consumption["lp"] = props.data[props.item]["Total Consumption"]["lp"]; 
        consumption["ytd"] = props.data[props.item]["Total Consumption"]["ytd"]; 
        consumption["atd"] = props.data[props.item]["Total Consumption"]["atd"]; 


        physicalAlbums["tp"] = props.data[props.item]["U.S. Physical Albums"]["tp"]; 
        physicalAlbums["lp"] = props.data[props.item]["U.S. Physical Albums"]["lp"]; 
        physicalAlbums["ytd"] = props.data[props.item]["U.S. Physical Albums"]["ytd"]; 
        physicalAlbums["atd"] = props.data[props.item]["U.S. Physical Albums"]["atd"]; 

        streaming["tp"] = props.data[props.item]["U.S. Total Streaming"]["tp"]; 
        streaming["lp"] = props.data[props.item]["U.S. Total Streaming"]["lp"]; 
        streaming["ytd"] = props.data[props.item]["U.S. Total Streaming"]["ytd"]; 
        streaming["atd"] = props.data[props.item]["U.S. Total Streaming"]["atd"]; 


        return ( 
            <TableContainer component={Paper}>
            <Table  aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell style={{textAlign:'left', paddingLeft:'16px'}}>{`${props.item.split('_')[0]} - Album`}</TableCell>
                
                    <TableCell align="right">This Week</TableCell>
                    <TableCell align="right">Last Week</TableCell>
                    <TableCell align="right">% Change</TableCell>
                    <TableCell align="right">Year To Date</TableCell>
                    <TableCell align="right">Life To Date</TableCell>
                    
                </TableRow>
                </TableHead>
                <TableBody>

                <>
                <TableRow
                        key={`Total Consumption`}>
                        <TableCell component="th" scope="row">
                            {`Total Consumption`}
                        </TableCell>
                        <TableCell align="right">{consumption.tp.toLocaleString()}</TableCell>
                        <TableCell align="right">{consumption.lp.toLocaleString()}</TableCell>
                        <TableCell align="right">{`${(!!consumption.lp ? parseFloat((((consumption.tp-consumption.lp) / consumption.lp) * 100).toFixed(1)) : 0).toLocaleString()}%`}</TableCell>
                        <TableCell align="right">{consumption.ytd.toLocaleString()}</TableCell>
                        <TableCell align="right">{consumption.atd.toLocaleString()}</TableCell>
                    </TableRow>
                    
                    <TableRow
                        key={`U.S. Physical Albums`}
                    >
                        <TableCell component="th" scope="row">
                            {`U.S. Physical Albums`}
                        </TableCell>
                        <TableCell align="right">{physicalAlbums.tp.toLocaleString()}</TableCell>
                        <TableCell align="right">{physicalAlbums.lp.toLocaleString()}</TableCell>
                        <TableCell align="right">{`${(!!physicalAlbums.lp ? parseFloat((((physicalAlbums.tp-physicalAlbums.lp) / physicalAlbums.lp) * 100).toFixed(1)) : 0).toLocaleString()}%`}</TableCell>
                        <TableCell align="right">{physicalAlbums.ytd.toLocaleString()}</TableCell>
                        <TableCell align="right">{physicalAlbums.atd.toLocaleString()}</TableCell>
                    </TableRow>

                    <TableRow
                        key={`U.S. Total Streaming`}
                    >
                        <TableCell component="th" scope="row">
                            {`U.S. Total Streaming`}
                        </TableCell>
                        <TableCell align="right">{streaming.tp.toLocaleString()}</TableCell>
                        <TableCell align="right">{streaming.lp.toLocaleString()}</TableCell>
                        <TableCell align="right">{`${(!!streaming.lp ? parseFloat((((streaming.tp-streaming.lp) / streaming.lp) * 100).toFixed(1)) : 0).toLocaleString()}%`}</TableCell>
                        <TableCell align="right">{streaming.ytd.toLocaleString()}</TableCell>
                        <TableCell align="right">{streaming.atd.toLocaleString()}</TableCell>
                    </TableRow>
                   

            </>                        
                          
            </TableBody>
            </Table>
        </TableContainer>
        )
    }

    const trackData = () => { 
        let consumption = {tp: 0, lp: 0, ytd: 0, atd: 0}; 
        let streaming = {tp: 0, lp: 0, ytd: 0, atd: 0}; 
        let downloads = {tp: 0, lp: 0, ytd: 0, atd: 0}; 
        let globalStreaming = {tp: 0, lp: 0, ytd: 0, atd: 0}; 
        let globalAudioStreaming = {tp: 0, lp: 0, ytd: 0, atd: 0};
        let globalVideoStreaming = {tp: 0, lp: 0, ytd: 0, atd: 0}; 

        downloads["tp"] = props.data[props.item]["Global Track Downloads"]["tp"]; 
        downloads["lp"] = props.data[props.item]["Global Track Downloads"]["lp"]; 
        downloads["ytd"] = props.data[props.item]["Global Track Downloads"]["ytd"]; 
        downloads["atd"] = props.data[props.item]["Global Track Downloads"]["atd"]; 

        globalStreaming["tp"] = props.data[props.item]["Global Total Streaming"]["tp"]; 
        globalStreaming["lp"] = props.data[props.item]["Global Total Streaming"]["lp"]; 
        globalStreaming["ytd"] = props.data[props.item]["Global Total Streaming"]["ytd"]; 
        globalStreaming["atd"] = props.data[props.item]["Global Total Streaming"]["atd"]; 

        globalAudioStreaming["tp"] = props.data[props.item]["Global Audio Streaming"]["tp"]; 
        globalAudioStreaming["lp"] = props.data[props.item]["Global Audio Streaming"]["lp"]; 
        globalAudioStreaming["ytd"] = props.data[props.item]["Global Audio Streaming"]["ytd"]; 
        globalAudioStreaming["atd"] = props.data[props.item]["Global Audio Streaming"]["atd"]; 


        globalVideoStreaming["tp"] = props.data[props.item]["Global Video Streaming"]["tp"]; 
        globalVideoStreaming["lp"] = props.data[props.item]["Global Video Streaming"]["lp"]; 
        globalVideoStreaming["ytd"] = props.data[props.item]["Global Video Streaming"]["ytd"]; 
        globalVideoStreaming["atd"] = props.data[props.item]["Global Video Streaming"]["atd"]; 

        consumption["tp"] = props.data[props.item]["U.S. Track Consumption"]["tp"]; 
        consumption["lp"] = props.data[props.item]["U.S. Track Consumption"]["lp"]; 
        consumption["ytd"] = props.data[props.item]["U.S. Track Consumption"]["ytd"]; 
        consumption["atd"] = props.data[props.item]["U.S. Track Consumption"]["atd"]; 

        streaming["tp"] = props.data[props.item]["U.S. Total Streaming"]["tp"]; 
        streaming["lp"] = props.data[props.item]["U.S. Total Streaming"]["lp"]; 
        streaming["ytd"] = props.data[props.item]["U.S. Total Streaming"]["ytd"]; 
        streaming["atd"] = props.data[props.item]["U.S. Total Streaming"]["atd"]; 


        return (
            <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell style={{ textAlign:'left', paddingLeft:'16px'}}>{`${props.item.split('_')[0]} - Track`}</TableCell>
                
                    <TableCell align="right">This Week</TableCell>
                    <TableCell align="right">Last Week</TableCell>
                    <TableCell align="right">% Change</TableCell>
                    <TableCell align="right">Year To Date</TableCell>
                    <TableCell align="right">Life To Date</TableCell>
                    
                </TableRow>
                </TableHead>
                <TableBody>

                <>
                <TableRow
                        key={`Global Track Downloads`}>
                        <TableCell component="th" scope="row">
                            {`Global Track Downloads`}
                        </TableCell>
                        <TableCell align="right">{downloads.tp.toLocaleString()}</TableCell>
                        <TableCell align="right">{downloads.lp.toLocaleString()}</TableCell>
                        <TableCell align="right">{`${(!!downloads.lp ? parseFloat((((downloads.tp-downloads.lp) / downloads.lp) * 100).toFixed(1)) : 0).toLocaleString()}%`}</TableCell>
                        <TableCell align="right">{downloads.ytd.toLocaleString()}</TableCell>
                        <TableCell align="right">{downloads.atd.toLocaleString()}</TableCell>
                    </TableRow>
                    
                    <TableRow
                        key={`Global Total Streaming`}
                    >
                        <TableCell component="th" scope="row">
                            {`Global Total Streaming`}
                        </TableCell>
                        <TableCell align="right">{globalStreaming.tp.toLocaleString()}</TableCell>
                        <TableCell align="right">{globalStreaming.lp.toLocaleString()}</TableCell>
                        <TableCell align="right">{`${(!!globalStreaming.lp ? parseFloat((((globalStreaming.tp-globalStreaming.lp) / globalStreaming.lp) * 100).toFixed(1)) : 0).toLocaleString()}%`}</TableCell>
                        <TableCell align="right">{globalStreaming.ytd.toLocaleString()}</TableCell>
                        <TableCell align="right">{globalStreaming.atd.toLocaleString()}</TableCell>
                    </TableRow>

                    <TableRow
                        key={`Global Audio Streaming`}
                    >
                        <TableCell component="th" scope="row">
                            {`Global Audio Streaming`}
                        </TableCell>
                        <TableCell align="right">{globalAudioStreaming.tp.toLocaleString()}</TableCell>
                        <TableCell align="right">{globalAudioStreaming.lp.toLocaleString()}</TableCell>
                        <TableCell align="right">{`${(!!globalAudioStreaming.lp ? parseFloat((((globalAudioStreaming.tp-globalAudioStreaming.lp) / globalAudioStreaming.lp) * 100).toFixed(1)) : 0).toLocaleString()}%`}</TableCell>
                        <TableCell align="right">{globalAudioStreaming.ytd.toLocaleString()}</TableCell>
                        <TableCell align="right">{globalAudioStreaming.atd.toLocaleString()}</TableCell>
                    </TableRow>
                   
                    <TableRow
                        key={`Global Video Streaming`}
                    >
                        <TableCell component="th" scope="row">
                            {`Global Video Streaming`}
                        </TableCell>
                        <TableCell align="right">{globalVideoStreaming.tp.toLocaleString()}</TableCell>
                        <TableCell align="right">{globalVideoStreaming.lp.toLocaleString()}</TableCell>
                        <TableCell align="right">{`${(!!globalVideoStreaming.lp ? parseFloat((((globalVideoStreaming.tp-globalVideoStreaming.lp) / globalVideoStreaming.lp) * 100).toFixed(1)) : 0).toLocaleString()}%`}</TableCell>
                        <TableCell align="right">{globalVideoStreaming.ytd.toLocaleString()}</TableCell>
                        <TableCell align="right">{globalVideoStreaming.atd.toLocaleString()}</TableCell>
                    </TableRow>
                   
                    <TableRow
                        key={`U.S. Track Consumption`}>
                        <TableCell component="th" scope="row">
                            {`U.S. Track Consumption`}
                        </TableCell>
                        <TableCell align="right">{consumption.tp.toLocaleString()}</TableCell>
                        <TableCell align="right">{consumption.lp.toLocaleString()}</TableCell>
                        <TableCell align="right">{`${(!!consumption.lp ? parseFloat((((consumption.tp-consumption.lp) / consumption.lp) * 100).toFixed(1)) : 0).toLocaleString()}%`}</TableCell>
                        <TableCell align="right">{consumption.ytd.toLocaleString()}</TableCell>
                        <TableCell align="right">{consumption.atd.toLocaleString()}</TableCell>
                    </TableRow>

                    <TableRow
                        key={`U.S. Total Streaming`}
                    >
                        <TableCell component="th" scope="row">
                            {`U.S. Total Streaming`}
                        </TableCell>
                        <TableCell align="right">{streaming.tp.toLocaleString()}</TableCell>
                        <TableCell align="right">{streaming.lp.toLocaleString()}</TableCell>
                        <TableCell align="right">{`${(!!streaming.lp ? parseFloat((((streaming.tp-streaming.lp) / streaming.lp) * 100).toFixed(1)) : 0).toLocaleString()}%`}</TableCell>
                        <TableCell align="right">{streaming.ytd.toLocaleString()}</TableCell>
                        <TableCell align="right">{streaming.atd.toLocaleString()}</TableCell>
                    </TableRow>
                   

            </>                        
                          
            </TableBody>
            </Table>
             {/* Disclaimer for Global Streaming LTD */}
            <p className="disclaimer">
                * Global Streaming LTD data is from Week 40, 2018.
            </p>
        </TableContainer>
        )
    }

    return (
    <Grid item display="flex" flexDirection="row">
                {props.item.length > 0 
                    && props.data != null
                    && props.albumOrTrack === "Album" 
                    && props.data[props.item] !== undefined
                    && props.data[props.item]["Total Consumption"] !== undefined
                    && props.data[props.item]["U.S. Physical Albums"] !== undefined
                    && props.data[props.item]["U.S. Total Streaming"] !== undefined ? 
                    albumData() : null}


                {props.item.length > 0 
                    && props.data != null
                    && props.albumOrTrack === "Track" 
                    && props.data[props.item] !== undefined 
                    && props.data[props.item]["Global Track Downloads"] !== undefined
                    && props.data[props.item]["Global Total Streaming"] !== undefined
                    && props.data[props.item]["Global Audio Streaming"] !== undefined 
                    && props.data[props.item]["Global Video Streaming"] !== undefined 
                    && props.data[props.item]["U.S. Track Consumption"] !== undefined 
                    && props.data[props.item]["U.S. Total Streaming"] !== undefined ? 
                    trackData() : null}
                        
             </Grid>
    )
}