import { FunctionComponent, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useHistory hook from react-router-dom

import logo from "../../assets/rn_logo_black.png";
import "./Sidebar.scss";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetState } from "../__shared__/reducer/user";

import { ReactComponent as ConsmptionIcon } from "../../assets/icons/Consumption.svg";
import { ReactComponent as RevenueCalcIcon } from "../../assets/icons/Revenue Calculator.svg";
import { ReactComponent as CertificationTrackerIcon } from "../../assets/icons/Certification Tracker .svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout.svg";
import { AppContext } from "../../contexts/AppContext";

import Button from "@mui/material/Button";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-x.svg";

interface SidebarProps {
  toggleDrawer: (newOpen: boolean) => void;
}

const Sidebar: FunctionComponent<SidebarProps> = ({ toggleDrawer }) => {
  const { section, setSection } = useContext(AppContext);

  const location = useLocation(); // Hook to get the current location
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/consumption") {
      setSection("consumption");
    } else if (location.pathname === "/revenueCalculator") {
      setSection("revenue calculator");
    } else if (location.pathname === "/certificationTracker") {
      setSection("certification tracker");
    }
  }, [location.pathname, setSection]);

  const isActive = (path: string): boolean => {
    return location.pathname === path;
  };

  const logout = () => {
    console.log("Logging out...");
    dispatch(resetState());
  };

  return (
    <aside className="sidebar">
      <header className="header-image">
        <div className="menuCloseHeader">
          <Button
            className="menuCloseButton"
            onClick={() => toggleDrawer(false)}
          >
            <CloseIcon />
          </Button>
        </div>
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
      </header>
      <nav>
        <ul>
          <li className={isActive("/consumption") ? "active" : ""}>
            <Link onClick={() => toggleDrawer(false)} to="/consumption">
              <ConsmptionIcon className="icon" />
              Consumption
            </Link>
          </li>
          <li className={isActive("/revenueCalculator") ? "active" : ""}>
            <Link onClick={() => toggleDrawer(false)} to="/revenueCalculator">
              <RevenueCalcIcon className="icon" />
              Revenue Calculator
            </Link>
          </li>
          <li className={isActive("/certificationTracker") ? "active" : ""}>
            <Link onClick={() => toggleDrawer(false)} to="/certificationTracker">
              <CertificationTrackerIcon className="icon" />
              Certification Tracker
            </Link>
          </li>
        </ul>
      </nav>
      <div className="logout-section">
        <LogoutIcon className="logout-icon" />
        <Link onClick={logout} to="">
          LOG OUT
        </Link>
      </div>
    </aside>
  );
};

export default Sidebar;
