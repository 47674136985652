import { FunctionComponent, 
    useCallback, 
    useContext, 
    useEffect, 
    useState } from "react";
import { CertificationTracker } from "./CertificationTracker";
import axios from "../../__shared__/axios"; 
import { ClientContext } from "../../../contexts/ClientContext";
import regression from 'regression';

type ContainerProps = {
clientId: string; 
}

type Metric = {
    name: string;
    value: any[]; 
  }
  
  type DataType = {
    metrics: Metric[];
  }
    
export const Container: FunctionComponent<ContainerProps> = (props) => {

    const { clientItem, setClientItem }  = useContext(ClientContext);
    const { client, setClient }  = useContext(ClientContext);
    const { clientDiscography, setClientDiscography }  = useContext(ClientContext);

    const [numberOfWeeks, _setNumberOfWeeks] = useState<any>(0)
    const [currentConsumption, _setCurrentConsumption] = useState<any>(0)


    const getAlbumData = useCallback(async (albumId: number, name: string) => { 
        try { 
            const body = {
                artistId: client.MusicConnectArtistID,
                albumId
            }
            
            
            const riaaData = await axios.post("/album/riaa-data", body)
            if (riaaData instanceof Error) throw (riaaData); 



            console.log("riaa data ", riaaData)

            const digitalSales: any[] = riaaData["data"]["digitalSales"];
            const digitalSongs: any[] = riaaData["data"]["digitalSongs"];
            const physicalSales: any[] = riaaData["data"]["physicalSales"]; 
            const streaming: any[] = riaaData["data"]["streaming"]; 


        
            let digitalSalesList: any[] = digitalSales.map((item) => {
                if (item.metrics.length > 0) { 
                    const atd: any[] = item.metrics[0]["value"].filter((value: any) => value.name === "atd")
                    if (atd.length > 0) { 
                        return {week: item["last_processing_date"], 
                                value: atd[0]["value"]}
                    }
                }

                return undefined
            })


            let digitalSongsList: any[] = digitalSongs.map((item) => {
                if (item.metrics.length > 0) { 
                    const atd: any[] = item.metrics[0]["value"].filter((value: any) => value.name === "atd")
                    if (atd.length > 0) { 
                        return {week: item["last_processing_date"], 
                                value: atd[0]["value"]}
                    }
                }
                return undefined
            })


            let physicalSalesList: any[] = physicalSales.map((item) => {
                if (item.metrics.length > 0) { 
                    const atd: any[] = item.metrics[0]["value"].filter((value: any) => value.name === "atd")
                    if (atd.length > 0) { 
                        return {week: item["last_processing_date"], 
                                value: atd[0]["value"]}
                    }
                }
                return undefined
            })

            let streamingList: any[] = streaming.map((item) => {
                if (item.metrics.length > 0) { 
                    const atd: any[] = item.metrics[0]["value"].filter((value: any) => value.name === "atd")
                    if (atd.length > 0) { 
                        return {week: item["last_processing_date"], 
                                value: atd[0]["value"]}
                    }
                }

                return undefined
            })


            digitalSalesList = digitalSalesList.filter((data) => data !== undefined)
            digitalSongsList = digitalSongsList.filter((data) => data !== undefined)
            physicalSalesList = physicalSalesList.filter((data) => data !== undefined)
            streamingList = streamingList.filter((data) => data !== undefined)



            const finalList = []
            for (let count = 0; count < 9; count++) {
                // Determine the week from whichever list has it, in a preferred order.
                const week = digitalSalesList[count]?.week ||
                             digitalSongsList[count]?.week ||
                             physicalSalesList[count]?.week ||
                             streamingList[count]?.week;
                             
                // If no week is available, skip this iteration.
                if (!week) continue;
              
                let value = 0;
                
                // If digitalSalesList at this index exists, add its value.
                if (digitalSalesList[count]?.value !== undefined) {
                  value += digitalSalesList[count].value;
                }
                
                // If digitalSongsList at this index exists, add its value divided by 10.
                if (digitalSongsList[count]?.value !== undefined) {
                  value += digitalSongsList[count].value / 10;
                }
                
                // If physicalSalesList at this index exists, add its value.
                if (physicalSalesList[count]?.value !== undefined) {
                  value += physicalSalesList[count].value;
                }
                
                // If streaming at this index exists, add its value divided by 1500.
                if (streamingList[count]?.value !== undefined) {
                  value += streamingList[count].value / 1500;
                }
                
                finalList.push({ week: count + 1, value });
            }



            console.log('final list:  ', finalList)

            const consumption = finalList[finalList.length - 1]["value"]
            const goals = [500000, 1000000, 2000000, 3000000, 4000000, 5000000, 6000000, 7000000, 8000000, 9000000, 10000000]
                
            for (const goal of goals) {
               if (consumption >= goal) {
                   // If current value already exceeds or equals the goal, skip this goal.
                   continue;
               }
                var weekTo = predictWeeks(finalList, goal);
                if (weekTo > 0) {
                    _setNumberOfWeeks(weekTo);
                    break;
                }
            }
            _setCurrentConsumption(consumption);

            
            


        } catch (error) { 
            console.log("GET ALBUM DATA TEST ", error); 

        }
    }, [clientItem])

    const getTrackData = useCallback(async (trackId: number, name: string) => { 
        try { 
            const body = {
                artistId: client.MusicConnectArtistID,
                trackId
            }
            
            
            const riaaData = await axios.post("/track/riaa-data", body)
            if (riaaData instanceof Error) throw (riaaData); 



            console.log(riaaData["data"])

            const digitalSongs: any[] = riaaData["data"]["digitalSongs"];
            const streaming: any[] = riaaData["data"]["streaming"]; 




            let digitalSongsList: any[] = digitalSongs.map((item) => {
                if (item.metrics.length > 0) { 
                    const atd: any[] = item.metrics[0]["value"].filter((value: any) => value.name === "atd")
                    if (atd.length > 0) { 
                        return {week: item["last_processing_date"], 
                                value: atd[0]["value"]}
                    }
                }

                return undefined
            })


 

            let streamingList: any[] = streaming.map((item) => {
                if (item.metrics.length > 0) { 
                    const atd: any[] = item.metrics[0]["value"].filter((value: any) => value.name === "atd")
                    if (atd.length > 0) { 
                        return {week: item["last_processing_date"], 
                                value: atd[0]["value"]}
                    }
                }

                return undefined
            })



            digitalSongsList = digitalSongsList.filter((data) => data !== undefined)
            streamingList = streamingList.filter((data) => data !== undefined)



            const finalList = []
            for (let count = 0; count < 9; count++) {
                // Determine the week from whichever list has it, in a preferred order.
                const week = digitalSongsList[count]?.week ||
                             streamingList[count]?.week;
                             
                // If no week is available, skip this iteration.
                if (!week) continue;
              
                let value = 0;
                
                
                // If digitalSongsList at this index exists, add its value divided by 10.
                if (digitalSongsList[count]?.value !== undefined) {
                  value += digitalSongsList[count].value;
                }
                
                // If streaming at this index exists, add its value divided by 1500.
                if (streamingList[count]?.value !== undefined) {
                  value += streamingList[count].value / 150;
                }
                
                finalList.push({ week: count + 1, value });
            }


            console.log("final track data list: ", finalList)
            const consumption = finalList[finalList.length - 1]["value"]

            const goals = [500000, 1000000, 2000000, 3000000, 4000000, 5000000, 6000000, 7000000, 8000000, 9000000, 10000000]
            
            for (const goal of goals) {
                if (consumption >= goal) {
                    // If current value already exceeds or equals the goal, skip this goal.
                    continue;
                }
                var weekTo = predictWeeks(finalList, goal);
                if (weekTo > 0) {
                    _setNumberOfWeeks(weekTo);
                    break;
                }
            }
            
            _setCurrentConsumption(consumption);

        } catch (error) { 
            console.log("GET ALBUM DATA TEST ", error); 

        }
    }, [clientItem])


    const getAlbumConsumption = useCallback(async (albumId: number, name: string) => { 
         try { 
             const body = { 
                 artistId: client.MusicConnectArtistID,
                 albumId,
             }

             const res = await axios.post(`/album/consumption`, body)
             if (res instanceof Error) throw (res); 

         
             if (res["status"] === 200 && res["data"] !== null) { 
                 if (res["data"]["error"])
					throw (res["data"]["error"]); 

                 const data: DataType[] = res["data"];

                 const consumpton =data[7].metrics[0].value.find(_  => _.name =='atd').value
                 const consumptions = [];
                 for (let i = 1; i <= data.length; i++) {
                     consumptions.push({ week: i, value: data[i-1].metrics[0].value.find(_  => _.name =='atd').value })                 
                 }
                 const goals = [500000, 1000000, 2000000, 3000000, 4000000, 5000000, 6000000, 7000000, 8000000, 9000000, 10000000]
                
                 for (const goal of goals) {
                    if (consumpton >= goal) {
                        // If current value already exceeds or equals the goal, skip this goal.
                        continue;
                    }
                     var weekTo = predictWeeks(consumptions, goal);
                     if (weekTo > 0) {
                         _setNumberOfWeeks(weekTo);
                         break;
                     }
                 }
                 _setCurrentConsumption(consumpton);

             }
         } catch (err) { 
             console.log("GET ALBUM US STREAMING err ", err); 
         }
    
    }, [clientItem])

    const getTrackConsumption = useCallback(async (songId: number, name: string) => { 
        try { 
            const body = { 
                artistId: client.MusicConnectArtistID,
                songId
            }

            const res = await axios.post(`/track/consumption`, body)
            if (res instanceof Error) throw (res); 
              
            if (res["status"] === 200 && res["data"] !== null) { 
                if (res["data"]["error"]) 
                    throw (res["data"]["error"]); 

                const data: DataType[] = res["data"];

                const consumpton = data[data.length-1].metrics[0].value.find(_  => _.name =='atd').value
                const consumptions: { week: number; value: any; }[] = [];
                for (let i = 1; i <= data.length; i++) {
                    consumptions.push({ week: i, value: data[i-1].metrics[0].value.find(_  => _.name =='atd').value })                 
                }
                const goals = [500000, 1000000, 2000000, 3000000, 4000000, 5000000, 6000000, 7000000, 8000000, 9000000, 10000000]
                
                for (const goal of goals) {
                    if (consumpton >= goal) {
                        // If current value already exceeds or equals the goal, skip this goal.
                        continue;
                    }
                    var weekTo = predictWeeks(consumptions, goal);
                    if (weekTo > 0) {
                        _setNumberOfWeeks(weekTo);
                        break;
                    }
                }
                
                _setCurrentConsumption(consumpton);
            }
        } catch (err) { 
            console.log("GET ALBUM US TOTAL CONSUMPTION err ", err); 
        }
    }, [clientItem])


    const predictWeeks = (data: any, target: any) => 
    {
        const regressionData = data.map((d: any) => [d.week, d.value]);
        console.log('regressionData: ', regressionData)
        const result = regression.linear(regressionData);
        const slope = result.equation[0];
        const intercept = result.equation[1];
        const weeksNeeded = (target - intercept) / slope;

        return Math.ceil(weeksNeeded); // Round up to the nearest whole week
    };

    const calculateCertification = () => {
        if (!!client?.MusicConnectArtistID && !!clientItem) {
            const type = clientDiscography[clientItem]["type"];
            const id =  clientDiscography[clientItem]["id"]; 
            if (type === "Album") { 
                console.log("GETTING DATA ");
                getAlbumData(id, client) 
                // getAlbumConsumption(id, client);
            } else { 
                console.log("GETTING TRACK DATA ")
                getTrackData(id, client); 
            }
        }
    }

    useEffect(() => {
        if (!client || !clientItem) { 
        _setCurrentConsumption(null);
        _setNumberOfWeeks(null);

        }
      }, [client, clientItem]);

    return (
        <CertificationTracker client={client} onCalculate={calculateCertification} numberOfWeeks={numberOfWeeks} currentConsumption={currentConsumption} />
    )
 }