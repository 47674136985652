export const revenueRates = [
{
    key: 'Spotify',
    value: 0.003
},
{
    key: 'Apple',
    value: 0.0062
},
{
    key: 'Amazon',
    value: 0.007
},
{
    key: 'Youtube',
    value: 0.00069
},
{
    key: 'Pandora',
    value: 0.0032
},
{
    key: 'TIDAL',
    value: 0.0068
},
{
    key: 'Audio',
    value: 0.004
},
{
    key: 'Video',
    value: 0.00069
},
{
    key: 'Default',
    value: 0.0034
}

]