import { Box, Button, FormControl, LinearProgress, Paper, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { FunctionComponent, useMemo, useState } from "react";
import './CertificationTracker.scss';
import React from "react";

type CertificationTrackerProps = { 
  client: any,
  numberOfWeeks: number;
  currentConsumption: number;
  onCalculate: () => void
}

const CustomPaper = styled(Paper)(() => ({
  boxShadow: 'none',
  border: '1px solid #CCC6BE',
  backgroundColor: '#F6F6F6',
}));

export const CertificationTracker: FunctionComponent<CertificationTrackerProps> = (props) => { 
 
  const displayUnits =
  props.currentConsumption >= 1000000
  ? Math.floor(props.currentConsumption / 1000000).toLocaleString() + 'M'
  : Math.floor(props.currentConsumption / 1000).toLocaleString() + 'K';
  
  const progressValues = [
    { label: 'Gold', value: 500000 },
    { label: 'Platinum', value: 1000000 },
    { label: '2X Multi-Platinum', value: 2000000 },
    { label: '3X Multi-Platinum', value: 3000000 },
    { label: '4X Multi-Platinum', value: 4000000 },
    { label: '5X Multi-Platinum', value: 5000000 },
    { label: '6X Multi-Platinum', value: 6000000 },
    { label: '7X Multi-Platinum', value: 7000000 },
    { label: '8X Multi-Platinum', value: 8000000 },
    { label: '9X Multi-Platinum', value: 9000000 },
    { label: 'Diamond', value: 10000000 }
  ];
  
  const status = progressValues.sort((x, y)=> y.value - x.value).find( x => props.currentConsumption >= x.value)?.label.toUpperCase() || '';
  
  const handleCalculate = () => {
    // Calculation logic goes here
    console.log('Calculating certification with');
    
    props.onCalculate();
  };
  
  const getPreviousAndNextValues = (currentConsumption: number): any[] => {
    let previous = null;
    let next = null;
    
    for (let i = 0; i < progressValues.sort((x, y)=> x.value - y.value).length; i++) {
      if (progressValues[i].value < currentConsumption) {
        previous = progressValues[i];
      } else {
        next = progressValues[i];
        break;
      }
    }
    
    return [ previous, next ];
  };
  
  let currentProgressValues = useMemo(() => getPreviousAndNextValues(props.currentConsumption), [props.currentConsumption]);

  currentProgressValues = currentProgressValues.filter((val) => val !== null)
  console.log(currentProgressValues)
  return (
    <div>
      <Button className='calculate-certification-button'
        sx={{ textTransform: "none" }}
        variant="contained"
        onClick={handleCalculate}
      >
        Calculate
      </Button>

      { props.client && props.currentConsumption > 0 && (
        <CustomPaper className="customPaper" elevation={3} sx={{ mt: 3, p: 2 }}>
          <Typography className="typography-header" variant="subtitle1" align="left" sx={{ mb: 7 }}>
            Current status: <b style={{color:'#000'}}>{status}</b> ({Math.floor(props.currentConsumption).toLocaleString()} UNITS)
          </Typography>
          <Box sx={{ position: 'relative', width: '90%', mt: 4, mb: 2 }}>


          {currentProgressValues[0].label !== "Diamond" ? 

              <>
            <LinearProgress 
              variant="determinate" 
              value={((props.currentConsumption >= currentProgressValues[currentProgressValues.length - 1].value  ? currentProgressValues[currentProgressValues.length - 1].value  : props.currentConsumption) / currentProgressValues[currentProgressValues.length - 1].value) * 100} 
              sx={{ height: 10, borderRadius: 5, backgroundColor: '#FFFFFF' }}
            /> 
     
            {currentProgressValues.map((item, index) => (
            !!item ?
              <React.Fragment key={item.value}>
                <Box 
                  sx={{
                    position: 'absolute',
                    top: -5,
                    left: index === 0 ? '50%' : '100%', // Adjusted to fit all values
                    transform: 'translateX(-50%)',
                    width: 20, // Reduced width
                    height: 20, // Reduced height
                    boxShadow: (props.currentConsumption < currentProgressValues[currentProgressValues.length - 1].value ? props.currentConsumption != item.value : props.currentConsumption >= item.value)  ? 'none' : '0 0 2px 4px rgba(156, 134, 104, 0.22)', // Adjusted shadow
                    backgroundColor: props.currentConsumption < item.value ? '#FFFFFF' : '#CCC6BE',
                    borderRadius: '50%'
                  }}
                />
                <Typography 
                  sx={{
                    position: 'absolute',
                    top: 17, // Adjusted position
                    left: index === 0 ? '50%' : '100%', // Adjusted to fit all values
                    textAlign: 'center',
                    color: props.currentConsumption < item.value ? '#9C8668' : '#CCC6BE',
                    transform: 'translateX(-50%)',
                    width: 100,
                    fontSize: '10px' // Reduced font size
                  }}
                  className="typography-content"
                >
                  {item.label} <br />
                  { item.value < 1000000 ? Math.floor(item.value / 1000).toLocaleString() + 'K' :Math.floor(item.value / 1000000).toLocaleString() + 'M'}
                </Typography>
              </React.Fragment> 
            : null))} 
            </>
            : null }
          </Box>
          <Typography  className="typography-footer"  variant="subtitle2" align="left" sx={{ mt: 10 }}>
            {props.currentConsumption >= 10000000 ? '' : 
            props.currentConsumption >= 9000000 ?
            props.numberOfWeeks + ' weeks until diamond status (10M Units)' : 
            props.currentConsumption >= 8000000 ?
            props.numberOfWeeks + ' weeks until 9x multi-platinum status (9M Units)' : 
            props.currentConsumption >= 7000000 ?
            props.numberOfWeeks + ' weeks until 8x multi-platinum status (8M Units)' : 
            props.currentConsumption >= 6000000 ?
            props.numberOfWeeks + ' weeks until 7x multi-platinum status (7M Units)' : 
            props.currentConsumption >= 5000000 ?
            props.numberOfWeeks + ' weeks until 6x multi-platinum status (6M Units)' : 
            props.currentConsumption >= 4000000 ?
            props.numberOfWeeks + ' weeks until 5x multi-platinum status (5M Units)' : 
            props.currentConsumption >= 3000000 ?
            props.numberOfWeeks + ' weeks until 4x multi-platinum status (4M Units)' : 
            props.currentConsumption >= 2000000 ?
            props.numberOfWeeks + ' weeks until 3x multi-platinum status (3M Units)' :
            props.currentConsumption >= 1000000 ?
            props.numberOfWeeks + ' weeks until 2x multi-platinum status (2M Units)' : 
            props.currentConsumption >= 500000 ?
            props.numberOfWeeks + ' weeks until platinum status (1M Units)' : 
            props.numberOfWeeks + ' weeks until gold status (500K Units)'}
          </Typography>
        </CustomPaper>
      )}
    </div>
  );
};

export default CertificationTracker;
